"use client";

import classNames from "classnames";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  isOverflow?: boolean;
};

export function Card({ children, isOverflow = false }: Props) {
  let c = ["bg-white-100", "rounded-md", "min-h-full", "w-full", "shadow-sm"];
  if (isOverflow) {
    c.push("overflow-visible");
  }
  return <div className={classNames(c)}>{children}</div>;
}
